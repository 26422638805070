import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

export default function NotFound() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <title>404 - Page Not Found | Benjamin Tinsley</title>
        <meta name="description" content="Page not found" />
      </Helmet>

      <main>
        <div className="bg-indigo-800 font-body min-h-screen">
          <div className="container px-8 mx-auto pt-24 md:pt-48 pb-4 md:pb-16">
            <div className="text-yellow-100">
              <span className="font-display text-6xl md:text-8xl block mb-8">This page doesn't exist.</span>
              <span className="text-xl md:text-3xl tracking-wide">
                <span className="block mb-8">
                    You seem to have wandered off.
                </span>
                <Link
                  to="/"
                  className="underline hover:text-yellow-400"
                >
                  Let's get you back home
                </Link>.
              </span>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}